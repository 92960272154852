/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import ModalDownloadForm from 'components/shared/modal-download-form';

import { useLazyBlocks } from 'hooks/use-lazy-blocks';

const LazyBlocksPage = ({ data: { wpPage: data }, pageContext, location }) => {
  const { reactedContent } = useLazyBlocks(data.content);

  return (
    <>
      <LayoutMain
        seo={{ ...data.seo, date: pageContext.date }}
        headerLayout={data.acf.headerLayout}
        headerTheme={data.acf.headerTheme}
        footerTheme={data.acf.footerTheme}
        withBanner={pageContext.showBanner}
        withBottomOffset={data.acf.footerTheme === 'with-border'}
        pageContext={pageContext}
        location={location}
      >
        {reactedContent}
      </LayoutMain>
      {data.acf.fileForDownloading && (
        <ModalDownloadForm
          id={data.acf.fileForDownloading.id}
          postId={data.acf.fileForDownloading.databaseId}
          fileUrl={data.acf.fileForDownloading.acf.file.guid}
          title={data.acf.fileForDownloading.acf.title}
          description={data.acf.fileForDownloading.acf.description}
        />
      )}
    </>
  );
};

export default LazyBlocksPage;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      acf {
        headerLayout
        headerTheme
        footerTheme
        fileForDownloading {
          ... on WpFileDownloading {
            id
            databaseId
            acf {
              title
              description
              file {
                guid
              }
            }
          }
        }
      }
      ...wpPageSeo
    }
  }
`;
